import {useFormik} from "formik";
import * as Yup from "yup";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const useCreateCompanyFormik = (opts: {
    onSubmit?: any;
    initialValues?: any;
}) => {
    const {t} = useTranslation("common");

    const memoizedT = useCallback(t, [t]);

    return useFormik({
        initialValues: {
            companyName: "",
            companyNumber: "",
            companyAddress: "",
            companyCity: "",
            phoneNumber: "",
            companyDescription: "",
            ...opts?.initialValues,
        },
        validateOnBlur: true,
        validateOnChange: true,
        validationSchema: Yup.object().shape({
            companyName: Yup.string().required(
                memoizedT("createCompany.companyDetails.form.companyName.required"),
            ),
            companyNumber: Yup.number()
                .required(
                    memoizedT("createCompany.companyDetails.form.companyNumber.required"),
                )
                .typeError(
                    memoizedT(
                        "createCompany.companyDetails.form.companyNumber.numberError",
                    ),
                ),
            companyDescription: Yup.string().required(
                memoizedT(
                    "createCompany.companyDetails.form.companyDescription.required",
                ),
            ),
            companyAddress: Yup.string().required(
                memoizedT("createCompany.companyDetails.form.companyAddress.required"),
            ),
            companyCity: Yup.string().required(
                memoizedT("createCompany.companyDetails.form.companyCity.required"),
            ),
            phoneNumber: Yup.string().required(
                memoizedT("verifyPhoneNumber.modal.sendMessage.required"),
            ),
        }),
        onSubmit: async (values, formikHelpers) => {
            await opts.onSubmit(values, formikHelpers);
        },
    });
};
